// Here you can add other styles
html {
  --color-1: #22324a;
  --color-2: #4f5d73;
  --color-3: #939aa7;
  --color-4: #e0e3e7;
  --color-5: #fff;
  --gap-s: 0.425rem;
  --gap: 0.995rem;
}

.box {
  padding: var(--gap);
  border-radius: var(--gap-s);
  background: var(--color-3);
}

.label-smaller {
  font-size: smaller;
}

.font-monospace {
  font-family: monospace;
}

.cursor-pointer {
  cursor: pointer;
}
